import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'



const hardware =()=>{
    return(
        <Layout>
            <SEO title="Hardware" />
            <div className="homeAuto">
                <Image alt="home automation" filename="hardware-banner.png"  />
                <div className="electricalImage">
                    <h1 style={{fontWeight:"bold", fontSize:"40px"}} className="hardH1">Mechanical Design of Enclosures,<br/>
                                Heat Sinks and More!</h1>
                </div>
            </div>

            <Container >
                <Row className="protoRow">
                   
                    <Col sm={12} lg={6} className="manuPara1">
                        <h3 className="hardwareh3">Hardware design services</h3>
                        <p>Trace Width offers a complete enclosure design with thermal analysis and material stress simulation. We utilize precision 3D modeling to get your design before creating a time consuming physical prototype. Trace Width can integrate advanced features of EMC, water proofing, and ruggedized structures to meet your stringent requirements. Using state of the art precision 3D-printing, we mock up your designs and create show worthy, proof-of-concept prototypes, all without breaking the bank.</p>
                        <h3 className="hardware1">Our Mechanical capabilities include:</h3> 
                        <ul>
                            <li>2D & 3D CAD Modeling and Manufacturing Quality Drawings for Components and Assemblies</li>
                            <li>Design with Advanced Metals and Plastics</li>
                            <li>Thermal & Shock Stress analysis (HALT & HASS Testing)</li>
                            <li>Heat Sink, Fan Design, Water Cooled enclosures and more…</li>
                            <li>Customize Cabling Solutions</li>
                            <li>Full Documentation and Certification Support</li>
                        </ul>
                    </Col>
                    <Col sm={12} lg={6} style={{paddingRight:"50px"}}><Image alt="hardware" filename="indeximage.png"  /></Col>
                    
                </Row>
              
                <Row >
                    <Col sm={12} lg={6}><Image alt="hardware" filename="IMG_9968b.jpg" /></Col>
                    <Col sm={12} lg={6} className="manuPara1">
                        <h3 className="hardware2">CUSTOM CIRCUIT BOARDS:</h3> 
                        <p>Trace Width offers a complete selection of hardware design services from concept to finished product.   We specialize in providing effective <a href="/electrical-design">PCB design services</a> to utilize in your product assembly.  When your company needs product prototyping, you can count on our team of elite engineers to deliver high quality PCB assemblies on time and at a competitive price. Our unique perspective of custom circuit board assemblies and PCB best practices comes from a clear understanding of the manufacturing processes involved and the industry’s quality specifications. With a wide range of prototype PCB fabrication capabilities, we can ensure that you can outpace your organization’s simple as well as complex electronics design requirements.</p>
                        <p>Trace Width has the experience and advanced tools necessary to handle all your advanced circuit needs. Our advanced CAD programs employ deep simulation and analysis that provides greater efficiency, accuracy, and quality control of the final product. Our goal is to be your preferred engineering source from the initial concept through the design phase to the completion of your custom printed circuit board products.</p>
                        <ul>
                            <li>Advanced Simulation and Reporting</li>
                            <li>Analysis of EMC, Thermal Stress, and more.</li>
                            <li>3D modeling outputs for mechanical compatibility</li>
                            <li>Industry Standards for Manufacturing Drafts and Assembly Documentation</li>
                            <li>Lead Free Design</li>
                            <li>International Design Standards as well as Product Import/Export Requirements</li>
                            <li>Advanced surface mount, BGA and Fine Pitch Parts Utilized in Multilayered Designs</li>
                            <li>Rigid-Flex</li>
                        </ul>
                    </Col>
                </Row>
            </Container>

            <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">Trace Width is answering the call to the technological solutions of tomorrow.</div>
            <div className="homeCardrow5">Get in touch with us today!</div>
            <ContactForm  />
        </div>

            </Layout>
    )
};

export default hardware;